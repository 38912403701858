<template>
  <div
    v-if="isShowing"
    :class="computedClasses"
  >
    <h2 class="heading">
      {{ t('heading') }}
      <FlagCanada class="flag" />
    </h2>
    <p class="content">
      {{ t('content') }}
    </p>
    <ZClose
      v-if="isDismissible"
      class="close"
      @click="handleDismiss"
    />
  </div>
</template>

<script setup lang="ts">
import { COOKIES } from '~/constants'
import { ONE_WEEK_IN_SECONDS } from '~/constants/durations'
import type { BannerVariant } from '~/components/banners/types'
import type { ComponentAlignmentHorizontal } from '~/components/style-guide/types'
import FlagCanada from '~/assets/images/flag-ca.svg'

const props = withDefaults(defineProps<{
  isDismissible?: boolean
  cookieName?: string
  variant?: BannerVariant
  alignment?: ComponentAlignmentHorizontal
}>(), {
  variant: 'primary',
  alignment: 'left',
})

const { isWebView } = usePlatform()
const { t } = useI18n()

const VERSION = 3

const computedClasses = computed(() => ([
  'highlight-banner',
  props.variant,
  props.alignment,
  {
    dismissible: props.isDismissible,
  },
]))

const cookieNameSuffix = props.cookieName ? `-${props.cookieName}` : ''
const internalCookieName = computed(() => `${COOKIES.highlightBanner}${cookieNameSuffix}-${VERSION}`)
const bannerCookie = useCookie(internalCookieName.value, {
  default: () => '0',
  maxAge: ONE_WEEK_IN_SECONDS,
})
const isShowing = computed(() => !Number(bannerCookie.value) && !isWebView.value)

function handleDismiss() {
  bannerCookie.value = '1'
}
</script>

<style lang="scss" scoped>
.highlight-banner {
  position: relative;
  background-color: getColor('primary-500');
  color: #fff;
  padding: 1rem 1.5rem;
  margin: 1.5rem 1rem;
  border-radius: 1rem;

  .heading {
    font-size: 18px;
    font-weight: 600;
    color: #ffd166;

    @include media-max-size(large) {
      @include strong-1;
    }

    @include media-min-size(large) {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .flag {
    vertical-align: text-bottom;
  }

  .content {
    margin: 0;
    @include body-1;
  }

  .close {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    transform: scale(0.75);
  }

  @include media-min-size(large) {
    display: flex;
    align-items: center;
    margin: 0;
    border-radius: 0;

    .heading {
      margin: 0 1rem 0 0;
    }

    .close {
      top: 50%;
      right: 0.75rem;
      transform: translateY(-50%) scale(0.75);
    }
  }

  &.primary-light {
    background-color: getColor('highlight-75');
    color: getColor('primary-500');

    .heading {
      color: getColor('danger-600');
    }
  }

  &.center {
    text-align: center;
    justify-content: center;
  }

  &.dismissible {
    .heading {
      margin-right: 1.5rem;

      @include media-min-size(large) {
        margin-right: 1rem;
      }
    }

    .content {
      margin-right: 2rem;
    }
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "heading": "Founded by Canadians. Operated by Canadians. Located in Canada.",
    "content": "Book your RV adventure today!"
  },
  "fr": {
    "heading": "Fondé par des Canadiens. Géré par des Canadiens. Situé au Canada.",
    "content": "Réservez votre aventure en VR dès aujourd’hui !"
  }
}
</i18n>
